import React, { useState, useEffect } from 'react';
import './Game.css'; // Import CSS file for styling

import languages from './ressources/language.json';
import spyIcon from './ressources/spy-icon.png'; // Import spy icon
import timerIcon from './ressources/clock-icon-small.png'; // Import timer icon

const Game = ({ players, theme, playtime, language, onBack }) => {
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [flipped, setFlipped] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null); // Time in seconds
    const [showingCards, setShowingCards] = useState(true); // Flag to track whether the cards are currently being shown
    const [spyIndex, setSpyIndex] = useState(0);
    const [randomWord, setRandomWord] = useState('');
    const [showSpy, setShowSpy] = useState(false);

    let timer;

    const getRandomWord = () => {
        // Mock function to generate random words (replace with actual word generation logic)
        let words; 
        if(theme === 'common-places') {
            if(language === 'en') {
                words = require('./places/en_common-places.json')['common-places'];
            } else if(language === 'de') {
                words = require('./places/de_common-places.json')['common-places'];
            }
        }

        return words[Math.floor(Math.random() * words.length)];
    }

    const handleCardClick = () => {
        if (!flipped) {
            setFlipped(true);
        } else {
            setFlipped(false);
            // Check if all players have seen their cards
            if (currentPlayerIndex >= players.length - 1) {
                // If all players have seen their cards, start the timer

                // Log the spy player and the word for debugging purposes
                console.log('The spy is', players[spyIndex].name);
                console.log('The word is', randomWord);
                console.log('These players are playing', players);
                console.log('Playtime is', playtime);


                setShowingCards(false);
                startTimer();
            }
            // Move to the next player
            setCurrentPlayerIndex(currentPlayerIndex + 1);
        }
    };

    const startTimer = () => {
        setRemainingTime(() => { return parseInt(playtime * 60, 10) });
        timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    }

    useEffect(() => {
        // Generate a random word for the non-spy players
        setRandomWord(() => {
            return getRandomWord();
        });
        // Randomly select a player to be the spy
        setSpyIndex(Math.floor(Math.random() * players.length));
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players.length, theme, language]);

    return (
        <div className="game-container">
            <div className='game-header'>
                {(showingCards || remainingTime > 0) &&
                    <button onClick={() => { onBack('local-lobby') }} className='primary-btn'>{languages[language].back_to_lobby_text}</button>
                }
                <button onClick={() => { onBack('menu') }} className='primary-btn'>{languages[language].back_to_menu_text}</button>
            </div>

            {showingCards && (
                <div>
                    {players.map((player, index) => (
                        <div
                            className={`player-card ${currentPlayerIndex === index ? 'active' : ''} ${index === spyIndex ? 'spy' : ''}`}
                            onClick={handleCardClick}
                            key={index}
                        >
                            <div className={`card-content ${flipped ? 'flipped' : ''}`}>
                                <div className="front">
                                    <h3>{player.name}</h3>
                                </div>
                                <div className="back">
                                    {index === spyIndex ? (
                                        <div>
                                            <img src={spyIcon} alt="Spy Icon" width={"32px"} />
                                            <h4>{languages[language].you_are_text}</h4>
                                            <h2>{languages[language].spy_text}</h2>
                                            <p>{languages[language].spy_info_text}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <h4>{languages[language].the_place_is_text}:</h4>
                                            <h2>{randomWord || ""}</h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!showingCards && remainingTime > 0 && (
                <div className="timer-container">
                    <img src={timerIcon} alt="Timer Icon" width={"128px"} />
                    <h2>{languages[language].time_remaining_text}: {Math.floor(remainingTime / 60)}:{remainingTime % 60 < 10 ? '0' : ''}{remainingTime % 60}</h2>
                </div>
            )}
            {!showingCards && remainingTime <= 0 && (
                <div className="game-over">
                    <h1>{languages[language].time_is_up_text}</h1>

                    {showSpy === true ? (
                        <div className="spy-info">
                            <img src={spyIcon} alt="Spy Icon" width={"32px"} />
                            <h2>{players[spyIndex].name}</h2>
                        </div>
                    ) : (
                        <button onClick={() => setShowSpy(true)} className='primary-btn'>{languages[language].reveal_spy_text}</button>
                    )}

                    <button onClick={() => window.location.reload()} className='primary-btn'>{languages[language].play_again_text}</button>
                </div>

            )}
        </div>
    );
}

export default Game;
