import React from 'react';

import './Menu.css';
import logo from './ressources/wordspy-logo.png';
import offlineIcon from './ressources/offline-internet-icon-small.png';
import onlineIcon from './ressources/online-internet-icon-small.png';

import languages from './ressources/language.json';

class Menu extends React.Component {
  render() {
    const { language } = this.props;

    return (
      <div className='menu-container'>
        <img src={logo} alt="Word Spy Logo" width={"360px"} />

        <select id="language-switch" className={this.props.language} value={this.props.language} onChange={this.handleLanguageSelected}>
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
        <div className='menu-controls'>
          <button id="offline-button" className="primary-btn play-btn" onClick={() => this.handlePlay('local-lobby')}>
            <span className='skew-fix'>
              <img src={offlineIcon} className="play-btn-icon" alt="Offline Icon" width={"32px"} />
              {languages[language].local_play_text}
            </span>
          </button>
          <button id="online-button" className="primary-btn play-btn" onClick={() => alert("Coming soon! Meanwhile you can play locally :)") && false && this.handlePlay('online-lobby')}>
            <span className='skew-fix'>
              <img src={onlineIcon} className="play-btn-icon" alt="Online Icon" width={"32px"} />
              {languages[language].online_play_text}
            </span>
          </button>
        </div>
      </div>
    );
  }

  handlePlay = (playType) => {
    // Pass the chosen play type to the parent component
    this.props.onPlaySelected(playType);
  }

  handleLanguageSelected = () => {
    // Pass the chosen language to the parent component
    this.props.onLanguageSelected(document.getElementById('language-switch').value);
  }
}

export default Menu;
