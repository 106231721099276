import React from 'react';

import './Lobby.css';

import languages from './ressources/language.json';
import trashIcon from './ressources/trash-icon_small.png';

class LocalLobby extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: JSON.parse(localStorage.getItem('players')) || [{ name: '' }],
            theme: 'common-places', // Default theme
            playtime: 5 // Default playtime
        };
    }

    handleThemeChange = (event) => {
        this.setState({ theme: event.target.value });
    }

    handleAddPlayer = () => {
        this.setState(prevState => ({
            players: [...prevState.players, { name: '' }]
        }));
    }

    handleRemovePlayer = (index) => {
        this.setState(prevState => ({
            players: prevState.players.filter((player, i) => i !== index)
        }));
    }

    handleNameChange = (index, event) => {
        const newPlayers = [...this.state.players];
        newPlayers[index].name = event.target.value;
        this.setState({ players: newPlayers });
    }

    handleStartGame = () => {
        // Pass the player information and chosen theme to the parent component
        localStorage.setItem('players', JSON.stringify(this.state.players));
        this.props.onStartGame(this.state.players, this.state.theme, this.state.playtime);
    }

    handlePlaytimeChange = (event) => {
        let value = parseInt(event.target.value, 10);
        switch (value) {
            case 1:
                value = 3;
                break;
            case 2:
                value = 5;
                break;
            case 3:
                value = 10;
                break;
            default:
                value = 3;
        }
        this.setState({ playtime: value });
    }

    render() {
        const { language } = this.props;

        return (
            <div className='local-menu__container'>
                <div className='local-menu__header'>
                    <button onClick={() => { this.props.onBack('menu') }} className='primary-btn'>{languages[language].back_text}</button>
                </div>

                <div className='local-menu__theme-container'>
                    <h3>{languages[language].what_places_text}</h3>
                    <div className="theme-selection">
                        <div className='theme-tile-container'>
                            <input type="radio" id="all" name="theme" value="all"
                                checked={this.state.theme === 'all'}
                                onChange={this.handleThemeChange} />
                            <label htmlFor="all" className="theme-tile">{languages[language].all_places_text} ({languages[language].difficulty_hard_text})</label>
                        </div>
                        <div className='theme-tile-container'>
                            <input type="radio" id="common-places" name="theme" value="common-places"
                                checked={this.state.theme === 'common-places'}
                                onChange={this.handleThemeChange} />
                            <label htmlFor="common-places" className="theme-tile">{languages[language].common_places_text} ({languages[language].difficulty_easy_text})</label>
                        </div>
                        <div className='theme-tile-container'>
                            <input type="radio" id="cities-and-countries" name="theme" value="cities-and-countries"
                                checked={this.state.theme === 'cities-and-countries'}
                                onChange={this.handleThemeChange} />
                            <label htmlFor="cities-and-countries" className="theme-tile">{languages[language].cities_and_countries_text} ({languages[language].difficulty_medium_text})</label>
                        </div>
                        <div className='theme-tile-container'>
                            <input type="radio" id="games-and-movies" name="theme" value="games-and-movies"
                                checked={this.state.theme === 'games-and-movies'}
                                onChange={this.handleThemeChange} />
                            <label htmlFor="games-and-movies" className="theme-tile">{languages[language].games_and_movies_text} ({languages[language].difficulty_hard_text})</label>
                        </div>
                        <div className='theme-tile-container'>
                            <input type="radio" id="famous-places" name="theme" value="famous-places"
                                checked={this.state.theme === 'famous-places'}
                                onChange={this.handleThemeChange} />
                            <label htmlFor="famous-places" className="theme-tile">{languages[language].famous_places_text} ({languages[language].difficulty_medium_text})</label>
                        </div>
                        {/* Add more theme options as needed */}
                    </div>
                    <label>{languages[language].places_info_text}</label>
                </div>

                <div className='local-menu__time-container'>
                    <div>
                        <label htmlFor="playtime">{languages[language].playtime_text}</label>
                        <input
                            type="range"
                            id="playtime"
                            name="playtime"
                            min="1"
                            max="3"
                            step="1"
                            value={
                                this.state.playtime === 3 ? 1 :
                                    this.state.playtime === 5 ? 2 :
                                        this.state.playtime === 10 ? 3 : 1
                            }
                            onChange={this.handlePlaytimeChange}
                        />
                        <span>{this.state.playtime} {languages[language].minutes_text}</span>
                    </div>
                </div>

                <div className='local-menu__players-container'>
                    <h3>{languages[language].who_is_playing_text}</h3>
                    {this.state.players.map((player, index) => (
                        <div className='player-input-container' key={index}>
                            <input
                                type="text"
                                placeholder={`Player ${index + 1} Name`}
                                value={player.name}
                                onChange={(event) => this.handleNameChange(index, event)}
                                className='player-input'
                            />
                            <button onClick={() => this.handleRemovePlayer(index)} className='primary-btn'>
                                <img width={"16px"} src={trashIcon} alt='Remove player' />
                            </button>
                        </div>
                    ))}
                    <div className='add-player-container'>
                        <button onClick={this.handleAddPlayer} className='primary-btn'>{languages[language].add_player_text}</button>
                    </div>
                </div>

                <div className='local-menu__start-container'>
                    <button onClick={this.handleStartGame} className='primary-btn'>{languages[language].start_game_text}</button>
                </div>
            </div>
        );
    }
}

export default LocalLobby;