import React from 'react';
import Menu from './Menu';
import LocalLobby from './LocalLobby';
import LocalGame from './LocalGame';

import './App.css';
import './ressources/normalize.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playType: null,
      players: [],
      theme: null,
      language: 'en',
      playtime: 5
    };
  }

  handlePlaySelected = (playType) => {
    // Update the playType state in the parent component for conditional rendering
    this.setState({ playType });
  }

  handleStartGame = (players, theme, playtime) => {
    // Update the players state in the parent component
    this.setState({ players, theme, playtime });
    // Now you can proceed with starting the game with the selected players and their themes
    this.setState({ playType: 'local-game' })
  }

  handleLanguageSelected = (language) => {
    // Update the language state in the parent component
    this.setState({ language });
  }

  handleBack = (state) => {
    // Update the playType state in the parent component to go back to the menu
    switch (state) {
      case 'menu':
        this.setState({ playType: null });
        break;
      case 'local-lobby':
        this.setState({ playType: 'local-lobby' });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        {this.state.playType === 'local-lobby' && (
          <LocalLobby onStartGame={this.handleStartGame} onBack={this.handleBack} language={this.state.language} />
        )}
        {this.state.playType === 'local-game' && (
          <LocalGame players={this.state.players} theme={this.state.theme} playtime={this.state.playtime} onBack={this.handleBack} language={this.state.language} />
        )}

        {!this.state.playType && (
          <Menu onPlaySelected={this.handlePlaySelected} onLanguageSelected={this.handleLanguageSelected} language={this.state.language} />
        )}
      </div>
    );
  }
}

export default App;
